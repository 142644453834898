import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import '../components/all.sass'

function addTextAreas() {
  let columns = document.getElementsByClassName('column')
  Array.from(columns).forEach((col) => {
    let textArea = document.createElement('textarea')
    textArea.className = 'textarea is-small'
    col.appendChild(textArea)
  })
}

function removeTextAreas() {
  let columns = document.getElementsByClassName('column')
  Array.from(columns).forEach((col) => {
    col.removeChild(col.lastChild)
  })
}

export default function ParticipantEvaluationForm({ pageContext, data }) {
  const { filePath } = pageContext
  const filePathParts = filePath.split('/')
  const productName = filePathParts[0]

  return (
    <Layout title="Participant Evaluation Form" filePath={filePath}>
      <h1 className="title is-1">{productName} Usability Evaluation</h1>
      <div id="text-areas">
        <div>
          <h3 className="title is-3">Email</h3>
          <textarea className="textarea is-small"></textarea>
          <br></br>
          <h3 className="title is-3">Customer User Title (Function)</h3>
          <textarea className="textarea is-small"></textarea>
          <br></br>
          <h3 className="title is-3">Facility</h3>
          <textarea className="textarea is-small"></textarea>
          <br></br>
          <h3 className="title is-3">Other Questions</h3>
          <div className="columns">
            <div className="column">
              <textarea className="textarea is-small"></textarea>
            </div>
            <div className="column">
              <textarea className="textarea is-small"></textarea>
            </div>
          </div>
        </div>
      </div>
      <button className="button" onClick={addTextAreas}>
        Add Row
      </button>
      <button className="button" onClick={removeTextAreas}>
        Remove Row
      </button>
    </Layout>
  )
}

//query all files in the parent directory
export const query = graphql`
  query MarkdownQueryParticipantEvaluationForm($parentPathRegex: String) {
    files: allFile(filter: { relativePath: { regex: $parentPathRegex } }) {
      edges {
        node {
          childMarkdownRemark {
            fields {
              filePath
            }
          }
        }
      }
    }
  }
`
